import type { Views } from '@donkeyjs/client';
import { ViewDeployment } from './ViewDeployment';
import { ViewException } from './ViewException';
import { ViewNotFound } from './ViewNotFound';

export const views: Views = {
  Deployment: { default: ViewDeployment },
  TraceEvent: { default: ViewException },
  NotFound: { default: ViewNotFound },
};

import { onMount, setState } from '@donkeyjs/jsx-runtime';
import { intlFormatDistance } from 'date-fns';

export function RelativeDate(props: { date: Date }) {
  const state = setState({ now: new Date() });

  onMount(() => {
    const dispose = setInterval(() => {
      state.now = new Date();
    }, 1000);

    return () => {
      clearInterval(dispose);
    };
  });

  return <>{() => intlFormatDistance(props.date, state.now)}</>;
}

import { createView } from '@donkeyjs/client';
import { RelativeDate } from '../components/relativeDate';
import listStyles from './DashboardList.module.css';
import styles from './ViewNotFound.module.css';

export const ViewNotFound = createView<DataSchema, 'NotFound'>(
  {
    name: () => 'Exception',
    groupings: [
      {
        key: 'path',
        format: (node) => node.appKey,
        name: () => 'Path',
        renderContainer: (group, _, children) => (
          <Group name={group.name}>{children}</Group>
        ),
      },
    ],
  },

  function ViewException(props) {
    return (
      <div class={styles.notFound}>
        <div class={styles.path}>{props.node.path}</div>
        <div class={styles.at}>
          <RelativeDate date={props.node.at} />
        </div>
      </div>
    );
  },
);

function Group(props: {
  name: string;
  children?: JSX.Children;
}) {
  return (
    <div class={[listStyles.list, styles.list]}>
      <div class={styles.name}>{props.name}</div>
      {props.children}
    </div>
  );
}

import { createView } from '@donkeyjs/client';
import { RelativeDate } from '../components/relativeDate';
import listStyles from './DashboardList.module.css';
import styles from './ViewDeployment.module.css';

export const ViewDeployment = createView<DataSchema, 'Deployment'>(
  {
    name: () => 'Deployment',
    groupings: [
      {
        key: 'appKey',
        format: (node) => node.appKey,
        name: () => 'App',
        renderContainer: (group, _, children) => (
          <Group name={group.name}>{children}</Group>
        ),
      },
    ],
  },

  function ViewDeployment(props) {
    return (
      <div>
        <RelativeDate date={props.node.at} />
      </div>
    );
  },
);

function Group(props: { name: string; children?: JSX.Children }) {
  return (
    <div
      class={[styles.group, listStyles.list]}
      onclick={() => {
        throw new Error('test');
      }}
    >
      <div class={styles.appKey}>{props.name}</div>
      <div class={styles.groupDeployments}>{props.children}</div>
    </div>
  );
}

import { defaultPermissions } from '@donkeyjs/core';
import { enGB } from '@donkeyjs/i18n-en-gb';
import { nlNL } from '../../i18n-nl-nl/src';
import { schema } from './schema';

export const settings = {
  hostname: 'dashboard.nandercirkel.dev',
  key: 'dashboard',
  locales: { 'en-GB': enGB, nl: nlNL },
  schema,
  permissions: defaultPermissions().with({
    viewer: {
      ...defaultPermissions().roles.visitor,
      Deployment: { read: 'allow' },
      TraceEvent: { read: 'allow' },
      Trace: { read: 'allow' },
      Exception: { read: 'allow', update: 'allow' },
      NotFound: { read: 'allow' },
    },
  }),
} as const;

import {
  PhFootprints,
  PhMouse,
  PhTerminal,
  PhTextbox,
  PhWarning,
} from '@donkeyjs/phosphor-icons';
import {
  type DataNode,
  type Insertable,
  isDataNode,
  meta,
} from '@donkeyjs/proxy';
import { Code } from '../components/Code';
import { LoadingSingleLine } from '../loaders';

export interface GroupedTraceEvent {
  item: Insertable<DataNode<DataSchema, 'TraceEvent'>>;
  count: number;
}

export const groupTraceEvents = (
  events: Insertable<DataNode<DataSchema, 'TraceEvent'>>[],
) => {
  const result: GroupedTraceEvent[] = [];
  let last: GroupedTraceEvent | undefined;

  for (const event of events) {
    if (
      last &&
      last.item.type === event.type &&
      last.item.target === event.target &&
      JSON.stringify(last.item.data) === JSON.stringify(event.data)
    ) {
      last.count++;
    } else {
      last = { item: event, count: 1 };
      result.push(last);
    }
  }

  return result;
};

export const traceIcon = (
  event: Pick<Insertable<DataNode<DataSchema, 'TraceEvent'>>, 'type'>,
) => icons[(event.type as keyof typeof icons) || 'EXCEPTION'];

export const traceColor = (
  event: Pick<Insertable<DataNode<DataSchema, 'TraceEvent'>>, 'type'>,
) => {
  if (event.type === 'EXCEPTION') return '#ff8888';
  if (event.type === 'CONSOLE') {
    const type = (event as any).data.type;
    if (type === 'log') return '#44ff44';
    if (type === 'warn') return '#ffa500';
    if (type === 'error') return '#ff8888';
    return undefined;
  }
  return undefined;
};

export const traceName = (
  event: Insertable<DataNode<DataSchema, 'TraceEvent'>>,
): JSX.Element => {
  if (isDataNode<DataSchema, 'TraceEvent'>(event) && meta(event).isLoading) {
    meta(event).request({
      type: true,
      target: true,
      data: true,
      exception: {
        id: true,
        message: true,
      },
    });
    return <LoadingSingleLine size="small" />;
  }

  if (event.type === 'ROUTING') {
    const queryString = event.target?.split('?')[1];
    return (
      <>
        {(event.data as any)?.routeName ? (
          <span>{(event.data as any).routeName}</span>
        ) : null}
        {event.target ? <Code>{event.target}</Code> : null}
        {queryString ? (
          <>
            {' '}
            <Code>{queryString}</Code>
          </>
        ) : null}
      </>
    );
  }

  if (event.type === 'INPUT') return <Code>{event.target}</Code>;

  if (event.type === 'CLICK') return <Code>{event.target}</Code>;

  if (event.type === 'CONSOLE')
    return <Code>{(event.data as any)?.args[0]?.toString() || 'console'}</Code>;

  const exception = event.exception;

  return (
    (exception && 'message' in exception
      ? (exception?.message as string)
      : undefined) || 'Exception'
  );
};

export const traceDescription = (
  event: Insertable<DataNode<DataSchema, 'TraceEvent'>>,
) => {
  if (event.type === 'ROUTING')
    return event.data ? (
      <Code block>
        {JSON.stringify(event.data, null, 2).replace(/\\n/g, '\n')}
      </Code>
    ) : null;
  if (event.type === 'INPUT') return null;
  if (event.type === 'CLICK') return null;

  if (event.type === 'CONSOLE')
    return (event.data as any)?.args.map((arg: any) => (
      <>
        {`console.${(event.data as any)?.type}`}
        <Code block>{arg?.toString?.() ?? 'Unknown arg'}</Code>
      </>
    ));

  const exception = event.exception;

  return () =>
    exception && (
      <>
        {() =>
          'line' in exception &&
          'col' in exception && (
            <Code block>{`${exception.line}:${exception.col}`}</Code>
          )
        }
        {(event.data as any)?.stack && (
          <Code block>{(event.data as any).stack}</Code>
        )}
      </>
    );
};

const icons = {
  CLICK: PhMouse,
  CONSOLE: PhTerminal,
  EXCEPTION: PhWarning,
  INPUT: PhTextbox,
  ROUTING: PhFootprints,
};

// const Time = styled.div`
//   color: ${(p) => p.theme.colors.primary};

//   font-size: 0.8em;
// `;

// const Code = styled.div`
//   margin-top: ${(p) => p.theme.spacing()};
//   padding: ${(p) => p.theme.spacing()};
//   border: solid 1px rgba(128, 128, 128, 0.2);

//   background: rgba(128, 128, 128, 0.1);

//   font-size: 9pt;
//   font-family: 'Hasklig Light', monospace;
//   text-align: left;
//   white-space: pre-wrap;
// `;

// const Const = styled.span`
//   padding: 0 4px;
//   border: solid 1px rgba(128, 128, 128, 0.2);

//   background: rgba(128, 128, 128, 0.1);

//   font-size: 9pt;
//   font-family: 'Hasklig Light', monospace;
// `;

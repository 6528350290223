import { jsxx, live } from '@donkeyjs/jsx-runtime';
import {
  meta,
  store,
  type DataList,
  type DataNode,
  type NodeTypename,
  type Schema,
} from '@donkeyjs/proxy';
import type { ViewGrouping, ViewMode, ViewProps, ViewType } from '.';
import { admin } from '../admin';
import { getUserContext } from '../authentication';
import { setDataContext } from '../data/DataContext';
import {
  draggable,
  dropZone,
  type DraggableOptions,
  type SortableListFunction,
  type UseDropZoneOptions,
} from '../dragdrop';
import { ErrorBoundary } from '../helpers';
import { session } from '../session';

export type ViewComponentProps<
  S extends Schema,
  Typename extends NodeTypename<S>,
> = {
  readonly node: DataNode<S, Typename>;
  readonly context?: DataList<S, Typename> | null;
  readonly view: ViewType<S, Typename>;
  readonly selected?: boolean | null;
  readonly readonly?: boolean;
  readonly mode?: ViewMode;
  readonly grouping?: ViewGrouping<S, Typename>;
  readonly setAsDefaultProperties?: boolean;
  readonly class?: JSX.ClassNames;
  readonly onmount?: JSX.OnMount<HTMLElement>;
  readonly draggable?: DraggableOptions<NodeTypename<DataSchema>>;
  readonly accept?: UseDropZoneOptions | undefined;
  readonly sortable?: SortableListFunction | null;
  readonly withoutOutline?: boolean;
};

export function View<S extends Schema, Typename extends NodeTypename<S>>(
  props: ViewComponentProps<S, Typename>,
) {
  const user = getUserContext();
  const selection = admin.useSelection?.();
  // const userI18n = getI18n(true);

  const state = store({
    needsAllDataLoad: null as boolean | null,
  });

  const outline =
    props.readonly || props.withoutOutline
      ? undefined
      : admin.useOutline?.(props.node, {
          view: props.view,
          context: props.context || undefined,
        });

  setDataContext(props.node, outline);

  live(() => {
    if (props.setAsDefaultProperties && selection && outline) {
      selection.setDefaultProperties(outline);
      return () => {
        selection.unsetDefaultProperties(outline);
      };
    }
  });

  const onmount = (el: HTMLElement) => {
    const mutation = session.router.lastMutation;
    if (mutation.anchor && mutation.anchor === props.node.id) {
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const viewProps = store<JSX.IntrinsicAttributes & ViewProps<S, Typename>>({
    get class() {
      return [
        `view view-${props.node?.__typename?.toLowerCase()}`,
        props.class,
        { 'node-testing': meta(props.node)?.isTest },
      ];
    },
    get selected() {
      return !!props.selected;
    },
    get href() {
      return props.selected
        ? session.router.getPath({ route: 'current' }, { emptyFor404: true })
        : session.router.getPath(
            { node: props.node as any },
            { emptyFor404: true },
          );
    },
    get readonly() {
      return props.readonly || !user.canEdit(props.node);
    },
    get node() {
      return props.node as any;
    },
    get context() {
      return props.context;
    },
    get mode() {
      return props.mode;
    },
    get grouping() {
      return props.grouping;
    },
    sortable: props.sortable || undefined,
    onmount: [
      props.onmount,
      onmount,
      outline?.onAttach,
      props.draggable && !outline?.accept
        ? draggable(props.draggable)
        : undefined,
      props.accept && !outline?.accept ? dropZone(props.accept) : undefined,
    ],
  });

  return (
    <ErrorBoundary>
      {() => {
        // Prevent lazy loading
        props.node.toString();

        return typeof props.view.component === 'function' ? (
          <>
            {jsxx(props.view.component, viewProps)}
            {() => {
              if (state.needsAllDataLoad === true) {
                state.needsAllDataLoad = false;
                return (
                  <div style="display: none">
                    {typeof props.view.component === 'function'
                      ? jsxx(
                          props.view.component,
                          store.clone(viewProps, {
                            selected: true,
                            onmount: undefined,
                          }),
                        )
                      : null}
                  </div>
                );
              }
            }}
          </>
        ) : null;
      }}
    </ErrorBoundary>
  );
}

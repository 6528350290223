import {
  Code,
  createView,
  traceColor,
  traceDescription,
  traceIcon,
  traceName,
} from '@donkeyjs/client';
import { isCrawler } from '@donkeyjs/core';
import { setState } from '@donkeyjs/jsx-runtime';
import { PhChat, PhCheck, PhPlusSquare } from '@donkeyjs/phosphor-icons';
import { UAParser } from 'ua-parser-js';
import { RelativeDate } from '../components/relativeDate';
import listStyles from './DashboardList.module.css';
import styles from './ViewException.module.css';

export const ViewException = createView<DataSchema, 'TraceEvent'>(
  {
    name: () => 'Exception',
    groupings: [
      {
        key: 'message',
        format: (node) => node.exception?.message || 'Unknown Exception',
        name: () => 'Message',
        renderContainer: (group, _, children) => (
          <Group
            name={group.name}
            at={group.items[0].at}
            regressed={!!group.items[0].exception?.regressed}
            onfixed={() => {
              group.items[0].exception!.fixed = true;
            }}
          >
            {children}
          </Group>
        ),
      },
    ],
  },

  function ViewException(props) {
    const state = setState({ expanded: false });

    const ua =
      props.node.trace.userAgent &&
      new UAParser(props.node.trace.userAgent).getResult();

    return (
      <div class={styles.event}>
        <button
          class={styles.header}
          type="button"
          onclick={() => (state.expanded = !state.expanded)}
        >
          <span class={styles.expander}>
            <PhPlusSquare />
          </span>
          <span class={styles.at}>
            <RelativeDate date={props.node.at} />
          </span>
          <div class={styles.tags}>
            <span>{props.node.trace?.userEmail}</span>
            <span>{props.node.appKey}</span>
            <span>{props.node.source}</span>
            <span>{ua ? ua.browser.name : ''}</span>
            <span>
              {props.node.trace.userAgent &&
              isCrawler(props.node.trace.userAgent)
                ? 'BOT'
                : ''}
            </span>
          </div>
        </button>
        {() =>
          state.expanded && (
            <div class={styles.trace}>
              {ua && (
                <div>
                  <div>
                    {ua.browser.name} {ua.browser.version}{' '}
                    {ua.os.name ? `on ${ua.os.name}` : ''}
                  </div>
                  <div>
                    <Code>{props.node.trace.userAgent}</Code>
                  </div>
                </div>
              )}
              {props.node.trace?.events.$((event) => (
                <div
                  class={styles.traceEvent}
                  style={`--event-color: ${traceColor(event) || 'inherit'};`}
                >
                  <div class={styles.traceEventIcon}>
                    {traceIcon(event)({ weight: 'light' })}
                  </div>
                  <div class={styles.traceDetails}>
                    {traceName(event)}
                    {traceDescription(event)}
                  </div>
                </div>
              ))}
            </div>
          )
        }
      </div>
    );
  },
);

interface GroupProps {
  name: string;
  at: Date;
  regressed?: boolean;
  onfixed: () => void;
  children?: JSX.Children;
}

function Group(props: GroupProps) {
  return (
    <div class={[listStyles.list, styles.list]}>
      <div class={styles.listButtons}>
        <button
          type="button"
          onclick={() => {
            props.onfixed();
          }}
        >
          <PhCheck />
        </button>
        <a
          href={`https://claude.ai/new?q=${encodeURIComponent(
            `How can I fix this exception? \`${props.name}\``,
          )}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PhChat weight="duotone" />
        </a>
      </div>
      <div class={styles.lastOccurrence}>
        <RelativeDate date={props.at} />
        {() =>
          props.regressed ? (
            <span class={styles.regressed}>regression</span>
          ) : null
        }
      </div>
      <div class={styles.name}>{props.name}</div>
      {props.children}
    </div>
  );
}

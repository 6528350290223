import { createClientApp } from '@donkeyjs/client';
import { settings } from '../settings';
import { Main } from './main';
import { theme } from './theme';
import { views } from './views';

export const app = createClientApp({
  ...settings,
  root: Main,
  backOffice: () => import('../backOffice').then((module) => module.backOffice),
  theme,
  views,
});
